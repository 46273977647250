// extracted by mini-css-extract-plugin
export var productDiscoverTestimonialSection = "v_cY";
export var productDiscoveryAchievementsSection = "v_c9";
export var productDiscoveryBenefitsSection = "v_cX";
export var productDiscoveryCaseSection = "v_c4";
export var productDiscoveryClientSection = "v_db";
export var productDiscoveryIndustriesSection = "v_cW";
export var productDiscoveryOverviewSection = "v_c2";
export var productDiscoveryProcessSection = "v_c1";
export var productDiscoveryProjLogoSection = "v_c8";
export var productDiscoveryResultsSection = "v_c5";
export var productDiscoveryRolesSection = "v_c3";
export var productDiscoveryServicesSection = "v_cV";
export var productDiscoveryStagesSection = "v_cZ";
export var productDiscoverySuccessStoriesSection = "v_c6";
export var productDiscoveryWhyChooseSection = "v_c7";
export var productDiscroveryImportanceSection = "v_c0";