// extracted by mini-css-extract-plugin
export var productDiscoverySession__card = "dw_Cy";
export var productDiscoverySession__cardFour = "dw_CD";
export var productDiscoverySession__cardOne = "dw_Cz";
export var productDiscoverySession__cardThree = "dw_CC";
export var productDiscoverySession__cardTwo = "dw_CB";
export var productDiscoverySession__cardWrapper = "dw_Cx";
export var productDiscoverySession__description = "dw_CH";
export var productDiscoverySession__header = "dw_Cw";
export var productDiscoverySession__image = "dw_CJ";
export var productDiscoverySession__info = "dw_CF";
export var productDiscoverySession__title = "dw_CG";